import React from "react";
import "./Hero.css";
import tercio from "../../assets/imgs/fototercio.png";
import titulo from "../../assets/imgs/titulotercio.svg";
const Hero = () => {
  return (
    <div className="hero-wrapper">
      <div className="hero-container">
        
        <div className="title">
          <img id="slide-in" className="hero-title" src={titulo} alt="tercio" />
        </div>
        <div>
        
          <img id="slide-in-right" className="tercioimg" src={tercio} alt="" />
        </div>
        

      </div>
   
    </div>
  );
};

export default Hero;
