import React from "react";
import "./Noticias.css";
import noticias from "./noticias.json";
import NoticiaContent from "./NoticiaContent";
import { Link } from "react-router-dom";
// Importar imagens
import image1 from "../../assets/imgs/sextou.jpeg";
import image2 from "../../assets/imgs/projetorejeitado.webp";
const imageMap = [image1, image2];

const main = process.env.REACT_APP_MAIN;
function Noticias({flag}) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (<>
 {flag !== 'home' &&  <div className="header-bg">
  </div>}

    <div className="noticias-wrapper">
      <div className="noticias-container">

        <div className="noticias-content">
          <h2>Virou notícia</h2>
          <p>Confira aqui o que Tércio fez que saiu na mídia</p>
        </div>
{flag !== 'home' && 
        <NoticiaContent data={noticias[0]}   />
}

        <div className="noticias-cards">
          {noticias.map((noticia, index) => (
            <div key={index} className="noticia-card">
              <img
                src={imageMap[index]}
                alt={noticia.title}
                className="noticia-images"
              />
              <div className="textos-news">
                <h3>{noticia.title}</h3>
                <p>{noticia.description}</p>
                <small>
                  {noticia.date} - {noticia.author}
                </small>
              </div>
            </div>
          ))}
        </div>
        {flag === 'home' && 
        <div className="redirect">
          <Link onClick={scrollToTop} to={main + "virounoticia"}>VEJA MAIS NOTÍCIAS</Link>
        </div>
}

      </div>
    </div>
    </>
  );
}

export default Noticias;
