import React, { useEffect } from 'react';
import './Inclusao.css';
import autismo from '../../assets/imgs/autismo.svg';
import praia from '../../assets/imgs/praia.svg';
import lacoautismo from '../../assets/imgs/lacoautismo.svg';
import cadeiras from '../../assets/imgs/11.png';
import pcd from '../../assets/imgs/pcd.svg';
import neuro from '../../assets/imgs/neuro.svg';

function Inclusao() {

  useEffect(() => {
    const slideInElement = document.getElementById('slide-in');

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          slideInElement.classList.add('slide-in-visible');
          observer.unobserve(slideInElement); // Stop observing once the animation has started
        }
      });
    }, {
      threshold: 0.1 // Adjust this value to control how much of the element needs to be visible before the animation starts
    });

    observer.observe(slideInElement);

    return () => {
      if (observer && slideInElement) {
        observer.unobserve(slideInElement);
      }
    };
  }, []);

  return (
    <div className='inclusao-wrapper'>
      <div className='inclusao-container'>
        <div className='inclusao-content'>
          <h2 id='slide-in' className='slide-in'>A inclusão é o que nos move!</h2>
          <p className='box-txt'>
            Tércio Tinoco é um vereador que se destaca por sua atuação em prol da inclusão e dos direitos das pessoas com deficiência. Ele promove iniciativas para melhorar a acessibilidade e garantir a participação plena dessas pessoas na sociedade.
          </p>
          <div className="icones">
            <img className='icones' src={lacoautismo} alt="" />
            <img className='icones' src={pcd} alt="" />
            <img className='icones' src={neuro} alt="" />
            
          </div>
        </div>
        <div className='inclusao-img'>
          <div className="card"><img src={autismo} alt="" /></div>
          <div className="card"><img src={cadeiras} alt="afa" /></div>
          <div className="card"><img src={praia} alt="afa" /></div>
        </div>
      </div>
    </div>
  );
}

export default Inclusao;
