const Link = 'Link';
const Scroll = 'Scroll';

const menuItems = [
    {
        label: 'Quem é Tércio?',
        type: Scroll,
        to: 'biografia',
    },
    {
        label: 'Nossos Projetos',
        type: Scroll,
        to: 'projetos',
    },
    {
        label: 'Virou Notícia',
        type: Scroll,
        to: 'noticias',
    },
    {
        label: 'Tampinha da Inclusão',
        type: Link,
        to: 'tampinhadainclusao',
    },
    {
        label: 'Nossos Trabalhos',
        type: Link,
        to: 'nossostrabalhos',
    }
];

export default menuItems;

