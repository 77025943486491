import React from "react";
import Slider from "react-slick";
import "./Tampinha.css";
import cartilha from "../../assets/imgs/frametampinha1.png";
import tampinha from "../../assets/imgs/logotampinha.png";
import tampinha2 from "../../assets/imgs/tampinhas.png";
import t1 from "../../assets/imgs/t1.png";
import t2 from "../../assets/imgs/t2.png";
import t3 from "../../assets/imgs/t3.png";
import t4 from "../../assets/imgs/t4.png";
import t5 from "../../assets/imgs/t5.png";
import t6 from "../../assets/imgs/t6.png";
import t7 from "../../assets/imgs/t7.png";
import t8 from "../../assets/imgs/t8.png";
import cartilhatampinha from "../../assets/docs/tampinhacartilha.pdf";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev-arrow`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

function Tampinha({ flag }) {
  const downloadPDF = () => {
    const link = document.createElement("a");
    link.href = cartilhatampinha;
    link.download = "tampinhacartilha.pdf";
    link.click();
  };

  const mensagemWhatsApp = "Olá, gostaria de saber como me voluntariar para o projeto Tampinha da Inclusão.";
  const numeroWhatsApp = "5584981402111";
  const linkWhatsApp = `https://wa.me/${numeroWhatsApp}?text=${encodeURIComponent(mensagemWhatsApp)}`;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {flag !== "home" && <div className="header-bg"></div>}
      <img src={cartilha} alt="" />
      <div className="tampinha-wrapper">
        <div className="tampinha-container">
          <div className="tampinha-content">
            <img className="img-tampinha" src={tampinha} alt="" />
            <div className="tampinha-titles">
              <div className="textos-flex">
                <img className="img-tampinha2" src={tampinha2} alt="" />
                <div className="texto">
                  <h1>O QUE É O TAMPINHA DA INCLUSÃO?</h1>
                  <p>
                    O Tampinha da Inclusão é um projeto social permanente fundado
                    pelo vereador Tércio Tinoco, com o objetivo de coletar
                    tampinhas 100% plásticas para reciclagem, ajudando na
                    aquisição de cadeiras de rodas e de banho para doação a
                    pessoas com deficiência, idosos ou com mobilidade reduzida.
                  </p>
                  <p>
                    Os voluntários realizam a coleta, limpeza, triagem,
                    armazenamento e identificação das tampinhas, que são então
                    enviadas para uma empresa parceira de reciclagem. As cadeiras
                    adquiridas são destinadas aos beneficiários cadastrados.
                  </p>
                  <p>
                    O projeto combina responsabilidade social com práticas
                    sustentáveis e de proteção ambiental, prevenindo o descarte
                    indiscriminado de plástico, que leva mais de 100 anos para se
                    decompor.
                  </p>
                </div>
              </div>
            </div>
            <div className="voluntariar">
              <h3>DESEJA SER VOLUNTÁRIO?</h3>
            </div>
            <div>
              <div className="botoes-tampinha">
                <a href={linkWhatsApp}>
                  <button className="entrectt">
                    <i className="uil uil-whatsapp"></i> Fale com o time do Tampinha
                  </button>
                </a>
                <button className="baixar" onClick={downloadPDF}>
                  Baixar Cartilha da Tampinha da Inclusão
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-container">
          <Slider {...settings}>
            <div className="slider-item"><img src={t1} alt="t1" /></div>
            <div className="slider-item"><img src={t2} alt="t2" /></div>
            <div className="slider-item"><img src={t3} alt="t3" /></div>
            <div className="slider-item"><img src={t4} alt="t4" /></div>
            <div className="slider-item"><img src={t5} alt="t5" /></div>
            <div className="slider-item"><img src={t6} alt="t6" /></div>
            <div className="slider-item"><img src={t7} alt="t7" /></div>
            <div className="slider-item"><img src={t8} alt="t8" /></div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Tampinha;
