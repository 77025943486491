import React from "react";
import "./Contato2.css";

import Mapa2 from "../mapa2/Mapa2";
import localizacao from "../../assets/imgs/localizacao.svg";

function Contato2() {
  const phoneNumber = "5584981882111";
  const message = "Olá! Estou entrando em contato pois desejo informações";
  const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;
  return (
    <div className="contato-wrapper">
      <div className="contato-container">
        <div className="contato-content">
          <div className="contatos">
            <div className="contato-img">
              <Mapa2 />
            </div>
            <div className="endereco">
              <div className="flex-row">
                <p className="endereco-nome">
                  ESCRITÓRIO DO VEREADOR TÉRCIO TINOCO - TAMPINHA DA INCLUSÃO
                </p>
              </div>
              <div className="flex-end">
                <div className="flex-row">
                  <img className="img-localizacao" src={localizacao} alt="" />{" "}
                  <p>Endereço:</p>
                </div>
                <p>Rua Marechal Rondon, 3490 - Candelária </p>
                <p>Natal - RN</p>
                <p>CEP: 59064-730</p>
                <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <i class="uil uil-whatsapp"> </i>
                  Entre em contato
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato2;
