import React from 'react';
import './NoticiasContent.css';
import noticias from "./imagensNoticias.json";
import img from  "../../assets/imgs/sextou.jpeg";

const NoticiaContent = ({ data }) => {
  const { date, title, description, author} = data;

  const imageUrl = noticias.length > 0 ? noticias[0].url : '';

  return (
    <div className="noticia-container">
      <img className="noticia-image" src={img} alt="" />
      <div className="flex-noticias">
        <h2 className="noticia-title">{title}</h2>
        <p className="noticia-date">{date}</p>
        <p className="noticia-description">{description}</p>
        <p className="noticia-author">Autor: {author}</p>
      </div>
    </div>
  );
};

export default NoticiaContent;
