import React, { useEffect } from "react";
import "./Biografia.css";

import bio from "../../assets/imgs/bio1.png";
import bio2 from "../../assets/imgs/bio6.png";
import bio3 from "../../assets/imgs/bio3.png";
import bio4 from "../../assets/imgs/bio4.png";
import bio5 from "../../assets/imgs/bio5.png";

function Biografia() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 } // Ajuste o threshold conforme necessário
    );

    const elements = document.querySelectorAll(".flex-bio, .flex-bio-reverse");
    elements.forEach((el) => {
      observer.observe(el);
    });

    // Cleanup observer on unmount
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="biografia-wrapper">
      <div className="bigrafia-container">
        <div className="titulos">
          <h1 className="main-title">Quem é Tércio Tinoco?</h1>
        </div>

        <div className="biografia-content">
          <div className="flex-bio-reverse invisible">
            <div className="textos">
              <h4 className="titulos">
                A Virada na Vida de Tércio: Acidente e Superação
              </h4>
              <p className="textos-p">
                Sua vida mudou aos 18 anos, quando ficou tetraplégico após bater
                a cabeça no fundo de uma piscina, ficando sem sinal de vida por
                15 minutos.
              </p>
              <h4 className="titulos">Vereador da Câmara Municipal de Natal</h4>
              <p className="textos-p">
                Tércio Tinôco é natalense, tem 36 anos, formado em administração
                e estreante na vida pública, tendo sido eleito o primeiro
                vereador cadeirante da Câmara Municipal de Natal. 
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio} alt="" />
            </div>
          </div>
          <div className="flex-bio invisible">
            <div className="textos">
              <h4 className="titulos">Readaptação e Paratletismo</h4>
              <p className="textos-p">
                Após o acidente, Tércio passou 12 dias na UTI e mais de 6 meses
                internado. Como parte do processo de adaptação à nova condição,
                ele começou a praticar tênis de mesa e tornou-se paratleta. Em
                2016, assumiu a presidência da SADEF (Sociedade Amigos do
                Deficiente Físico do RN), que é referência no paradesporto
                nacional e internacional, cargo que ocupou por mais de 5 anos.
              </p>
              <h4 className="titulos">
                Presidência da SADEF e Reconhecimento Nacional
              </h4>
              <p className="textos-p">
                Durante sua gestão, a SADEF recebeu o Prêmio Brasil Mais
                Inclusão da Câmara dos Deputados como reconhecimento ao trabalho
                realizado em prol das pessoas com deficiência.
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio2} alt="" />
            </div>
          </div>
          <div className="flex-bio-reverse invisible">
            <div className="textos">
              <h4 className="titulos">
                Projeto Natal Praia Inclusiva: Esporte e Lazer para Todos
              </h4>
              <p className="textos-p">
                Além disso, Tércio foi idealizador do Projeto Natal Praia
                Inclusiva, que oferece atividades esportivas e lazer, além de
                banho de mar assistido para pessoas com deficiência na praia de
                Ponta Negra. Tércio é o primeiro tetraplégico do estado a
                renovar sua habilitação e dirigir um veículo adaptado. 
              </p>
              <h4 className="titulos">
                Liderança pela Inclusão: Derrubada do Veto do IPVA
              </h4>
              <p className="textos-p">
                Tércio liderou o movimento para garantir a derrubada do veto da
                isenção de IPVA para pessoas com deficiência em 2019, na
                Assembleia Legislativa, é o primeiro tetraplégico do estado a
                renovar sua habilitação e dirigir um veículo adaptado. 
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio3} alt="" />
            </div>
          </div>
          <div className="flex-bio invisible">
            <div className="textos">
              <h4 className="titulos">
                Iniciativas para Próteses e Cadeiras de Rodas
              </h4>
              <p className="textos-p">
                Tércio lutou pela realização de licitações para o fornecimento
                de próteses, órteses e cadeiras de rodas no Rio Grande do Norte.
              </p>
              <h4 className="titulos">Tampinha da Inclusão</h4>
              <p className="textos-p">
                Para amenizar a espera de pessoas com deficiência ou mobilidade
                reduzida, Tércio criou em 2021 o Tampinha da Inclusão. O projeto
                se tornou Lei Municipal em 2023. A população é incentivada a
                coletar tampas de plástico, que são entregues ao gabinete e
                vendidas a uma empresa de reciclagem. Todo o valor arrecadado é
                revertido em cadeiras de rodas (passeio ou banho). Cada 200kg de
                tampas equivale a uma cadeira.
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio4} alt="" />
            </div>
          </div>
          <div className="flex-bio-reverse invisible">
            <div className="textos">
              <h4 className="titulos">
                Primeiro Mandato e Conquistas Legislativas
              </h4>
              <p className="textos-p">
                Em seu primeiro mandato, muitas conquistas foram alcançadas. O
                vereador apresentou 68 projetos de lei, dos quais 45 foram
                aprovados e outros seguem em tramitação. Ao todo foram
                apresentados 845 requerimentos, e 42 emendas ao Plano Diretor - o
                maior número entre todos os parlamentares, com foco
                principalmente na acessibilidade.
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio5} alt="" />
            </div>
          </div>
          <div className="titulos">
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Biografia;
