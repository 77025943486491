import React, { useEffect, useRef, useState } from "react";
import "./NumeroLeis.css";

function NumeroLei() {
  const [counts, setCounts] = useState([0, 0, 0, 0]);
  const containerRef = useRef(null);
  const finalCounts = [68, 75, 845, 42]; 

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          animateNumbers();
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const animateNumbers = () => {
    const duration = 2000; // Duração da animação em milissegundos
    const increment = 40; // Número de incrementos
    const interval = duration / increment;

    finalCounts.forEach((finalCount, index) => {
      let currentCount = 0;
      const step = finalCount / increment;

      const timer = setInterval(() => {
        if (currentCount < finalCount) {
          currentCount = Math.min(currentCount + step, finalCount);
          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = Math.round(currentCount);
            return newCounts;
          });
        } else {
          clearInterval(timer);
        }
      }, interval);
    });
  };

  return (
    <div className="lei-wrapper" ref={containerRef}>
      {["Projetos de Lei", "Leis Aprovadas", "Requerimentos", "Plano Diretor"].map((label, index) => (
        <div key={index} className="numleis-content">
          <div className="quadrado">
            <div className="numeros-lei">
              <h1>{counts[index]}</h1>
              <p>{label}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default NumeroLei;
