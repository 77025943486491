import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import acess from "./assets/imgs/acessibilidade.svg";
import zap from "./assets/imgs/falaa.svg";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/hero/Hero";
import Contato from "./components/contato/Contato";
import Contato2 from "./components/contato2/Contato2";
import Biografia from "./components/biografia/Biografia";
import NossosProjetos from "./components/nossosprojetos/NossosProjetos";
import Inclusao from "./components/inclusao/Inclusao";
import Noticias from "./components/noticias/Noticias";
import Tampinha from "./components/tampinha/Tampinha";
import Trabalhos from "./components/nossotrabalho/NossoTrabalho";
import menuItems from "./components/shared/data/menuItems";
import socialLinks from "./components/shared/data/socialLinks";
import NumLeis from "./components/numerosleis/NumeroLeis";
import handleNavigationBtn from "./components/shared/utils/handleNavigationBtn";
import Instagram from "../src/components/instagram/instagram";

import "./App.css";
import "./assets/styles/Reset.css";

function App() {
  const mensagemWhatsApp = "Olá, gostaria de falar com o vereador.";

  const numeroWhatsApp2 = "5584981402111"; // Número de telefone para o qual será enviado o WhatsApp

  const linkWhatsApp = `https://wa.me/${numeroWhatsApp2}?text=${encodeURIComponent(
    mensagemWhatsApp
  )}`;

  const main = process.env.REACT_APP_MAIN;
  const navigate = useNavigate();
  const location = useLocation();
  const [refs, setRefs] = useState({});

  useEffect(() => {
    const setupRefs = () => {
      const newRefs = {};

      menuItems.forEach((item) => {
        if (item.type === "Scroll") {
          newRefs[item.to] = React.createRef();
        }
      });

      setRefs(newRefs);
    };

    setupRefs();
  }, [menuItems]);

  const handleNavigation = async (item) => {
    await handleNavigationBtn(item, refs, navigate, location);
  };

  const toggleAcessibilidade = () => {
    document.querySelectorAll('h1, h2, h3, h4, p, a').forEach(element => {
      element.classList.toggle('acessibilidade');
    });
  };


  return (
    <div className="App">
      <Header
        handleNavigationBtn={handleNavigation}
        menuItems={menuItems}
        socialLinks={socialLinks}
      />

      <Routes>
        <Route
          path={main}
          element={
            <>
              <div className="body-wrapper">
                <div ref={refs.inicio}>
                  <Home />
                </div>
                <Inclusao />

                <div ref={refs.biografia}>
                  <Biografia />
                </div>

                <NumLeis />
                <Instagram />
                <div ref={refs.projetos}>
                  <NossosProjetos />
                </div>

                <div ref={refs.noticias}>
                  <Noticias flag={"home"} />
                </div>

                <Contato />
                <Contato2 />
              </div>
            </>
          }
        ></Route>

        <Route path={main + "virounoticia/"} element={<Noticias />}>
          {" "}
        </Route>

        <Route path={main + "tampinhadainclusao/"} element={<Tampinha />}>
          {" "}
        </Route>

        <Route path={main + "nossostrabalhos/"} element={<Trabalhos />}>
          {" "}
        </Route>
      </Routes>

      <div ref={refs.newsletter}>
        <Footer
          handleNavigationBtn={handleNavigation}
          menuItems={menuItems}
          socialLinks={socialLinks}
        />
      </div>

      <div className="whats-btn">
        <button onClick={toggleAcessibilidade} className="btn-acessibilidade">
         <img className="acessibilidade-btn" src={acess} alt="" /></button>

        <div className="link-with-image">
          <a href={linkWhatsApp}>
            <button className="entrecttverde">
              <i className="uil uil-whatsapp"></i> Fale com o vereador!
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
