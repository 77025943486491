// Footer.js
import React from "react";
import "./Footer.css";
import logo from "../../assets/imgs/logo.png";
import tt from "../../assets/imgs/TKTK.svg";
import x from "../../assets/imgs/XX.svg";

const Footer = ({ handleNavigationBtn, menuItems, socialLinks }) => {
  const phoneNumber = "5584996060201";
  const message = encodeURIComponent(
    "Gostei do desenvolvimento do seu site, gostaria de saber como funciona?"
  );
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

  return (
    <footer className="footer-background">
      <div className="footer-container">
        <div class="footer-container-division">
          <div className="footer-wrap">
            <div className="footer-logo">
              <img className="logo-footer" src={logo} alt="" />
            </div>
          </div>
          <div className="footer-wrap footer-content">
           
            <div className="footer-menu">
              <h5>NAVEGAÇÃO</h5>
              <div>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <button onClick={() => handleNavigationBtn(item)}>
                      {item.label}
                    </button>
                  </li>
                ))}
              </div>
            </div>
            <div className="footer-menu">
              <h5>CONTATOS E REDES SOCIAIS</h5>
              <div className="socials">
               
              
                <a
                  href="https://facebook.com/terciotinoco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="uil uil-facebook-f">         </i>facebook.com/terciotinoco
                </a>
                <br />
                <a
                  href="https://instagram.com/terciotinoco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="uil uil-instagram">         </i>instagram.com/terciotinoco
                </a>
                <br />

                <div className="tt">
                  <a
                    href="https://x.com/terciotinoco"
                    target="_blank"
                    rel="noreferrer"
                  >
                   <img className="ttimg" src={x} alt="" />  x.com/terciotinoco
                  </a>
                </div>
                <br />
                <a
                  href="https://tiktok.com/@terciotinoco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="tkimg" src={tt} alt="" /> tiktok.com/@terciotinoco
                </a>
                <br />
                <a
                  href="https://youtube.com/@TercioTinoco"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="uil uil-youtube">         </i>youtube.com/@TercioTinoco
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; Tércio Tinoco</p>
          </div>
        
        </div>
      </div>{" "}
      <div className="luana">
        <a href={whatsappUrl} target="_blank" rel="noreferrer">
          Desenvolvido por Luana Salmito
        </a>
      </div>
    </footer>
  );
};

export default Footer;
