import React from "react";
import "./Contato.css";
import Mapa from "../mapa/Mapa";
import Mapa2 from "../mapa2/Mapa2";
import localizacao from "../../assets/imgs/localizacao.svg";

function Contato() {
  const phoneNumber = "5584981402111";
  const message = "Olá! Estou entrando em contato pois desejo informações";
  const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;
  return (
    <div className="contato-wrapper">
      <div className="contato-container">
        <div className="contato-content">
          <h2>Contatos</h2>
          <p>Veja aqui onde obter mais informações</p>

          <div className="contatos">
            <div className="endereco">
              <div className="flex-row">
                <p className="endereco-nome">GABINETE DO VEREADOR TÉRCIO TINOCO</p>
              </div>
              <div className="flex-end">
                <div className="flex-row">
                  <img className="img-localizacao" src={localizacao} alt="" />{" "}
                  <p>Endereço:</p>
                </div>
                <p>Rua Jundiaí, 546 - Tirol </p>
                <p>Natal - RN</p>
                <p>CEP: 59020-120</p>
                <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
                  {" "}
                  <i class="uil uil-whatsapp"> </i>
                  Entre em contato
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contato-img">
          <Mapa />
        </div>
      </div>
    </div>
  );
}

export default Contato;
