import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './NossosProjetos.css';

import img1 from '../../assets/imgs/tampinha.jpg';
import img2 from '../../assets/imgs/sextou.jpg';
import img3 from '../../assets/imgs/movase.jpg';
import img4 from '../../assets/imgs/lutandoo.jpg';
import img5 from '../../assets/imgs/gabinete.jpg';

import img6 from '../../assets/imgs/tampinhamobile.jpg';
import img7 from '../../assets/imgs/sextoumobile.jpg';
import img8 from '../../assets/imgs/movasemobile.jpg';
import img9 from '../../assets/imgs/lutandoomobile.jpg';
import img10 from '../../assets/imgs/gabinetemobile.jpg';

import { NextArrow, PrevArrow } from '../prevarrow/PrevArrow'; // Ajuste o caminho conforme necessário

const desktopImages = [img1, img2, img3, img4, img5];
const mobileImages = [img6, img7, img8, img9, img10];

function NossosProjetos() {
  const [isVisible, setIsVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState(desktopImages);
  const nossosProjetosRef = useRef(null);

  useEffect(() => {
    const updateImageArray = () => {
      if (window.innerWidth <= 768) {
        setImageUrls(mobileImages);
      } else {
        setImageUrls(desktopImages);
      }
    };

    updateImageArray(); // Set the initial image array
    window.addEventListener('resize', updateImageArray); // Update the image array on resize

    return () => {
      window.removeEventListener('resize', updateImageArray);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.5, // O componente é considerado visível quando 50% dele está na viewport
      }
    );

    if (nossosProjetosRef.current) {
      observer.observe(nossosProjetosRef.current);
    }

    return () => {
      if (nossosProjetosRef.current) {
        observer.unobserve(nossosProjetosRef.current);
      }
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  return (
    <div className='nossosprojetos-wrapper' ref={nossosProjetosRef}>
      <div className='nossosprojetos-container'>
        <div className='nossosprojetos-content'>
          <h2>Nossos Projetos</h2>
        </div>
        <div className='nossosprojetos-img'>
          {isVisible && (
            <Slider {...settings}>
              {imageUrls.map((url, index) => (
                <div key={index}>
                  <img src={url} alt={`Slide ${index + 1}`} className="slide-image" />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}

export default NossosProjetos;