import React, { useEffect } from 'react';
import './instagram.css';
import tercio from '../../assets/imgs/insta11.jpeg';
import tercio2 from '../../assets/imgs/insta12.jpeg';
import tercio3 from '../../assets/imgs/insta13.jpeg';
import tercio4 from '../../assets/imgs/insta14.jpeg';
import tercio5 from '../../assets/imgs/insta15.jpeg';
import tercio6 from '../../assets/imgs/insta16.jpeg';
import tercio7 from '../../assets/imgs/insta17.jpeg';
import tercio8 from '../../assets/imgs/insta18.jpeg';
import instagram from '../../assets/imgs/instagram.svg';

function Inclusao() {

  useEffect(() => {
    const slideInElement = document.getElementById('slide-in');

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          slideInElement.classList.add('slide-in-visible');
          observer.unobserve(slideInElement); // Stop observing once the animation has started
        }
      });
    }, {
      threshold: 0.1 // Adjust this value to control how much of the element needs to be visible before the animation starts
    });

    observer.observe(slideInElement);

    return () => {
      if (observer && slideInElement) {
        observer.unobserve(slideInElement);
      }
    };
  }, []);

  return (
    <div className='inclusao-wrapper'>
      <div className='inclusao-container'>
        <div className='insta-content'>
          <h2 id='slide-in' className='slide-in'>Veja nossos últimos posts</h2>
          <img className='instagram-img' src={instagram} alt="" />
        </div>
      </div>
      <div className='inclusao-img'>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio} alt="" /></div>
        </a>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio2} alt="afa" /></div>
        </a>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio8} alt="afa" /></div>
        </a>
      </div>
      <div className='inclusao-img'>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio4} alt="" /></div>
        </a>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio5} alt="afa" /></div>
        </a>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio6} alt="afa" /></div>
        </a>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio7} alt="afa" /></div>
        </a>
        <a href='https://www.instagram.com/terciotinoco/' target='_blank' rel='noopener noreferrer'>
          <div className="card"><img src={tercio3} alt="afa" /></div>
        </a>
      </div>
    </div>
  );
}

export default Inclusao;
