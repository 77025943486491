import React, { useEffect } from "react";
import "./NossoTrabalho.css";

import bio from "../../assets/imgs/saude.png";
import bio2 from "../../assets/imgs/escolas.png";
import bio3 from "../../assets/imgs/lazer.png";
import bio4 from "../../assets/imgs/educacao.png";
import bio7 from "../../assets/imgs/bio7.png";
import bio6 from "../../assets/imgs/autismo.png";
import solicitacao from "../../assets/imgs/solicitacaoo.png";


function NossoTrabalho({ flag }) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 } // Ajuste o threshold conforme necessário
    );

    const elements = document.querySelectorAll(".flex-bio, .flex-bio-reverse");
    elements.forEach((el) => {
      observer.observe(el);
    });

    // Cleanup observer on unmount
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);

  return (
    <>
      {flag !== "home" && <div className="header-bg"></div>}
      <div className="biografia-wrapper">
        <div className="bigrafia-container">
          <div className="titulos">
            <h1 className="main-title">NOSSOS TRABALHOS LEGISLATIVOS</h1>
          </div>

          <div className="biografia-content">
            <div className="flex-bio-reverse invisible">
              <div className="textos">
                <h4 className="titulos">Saúde</h4>
                <p className="textos-p">
                  ° Luta pela inclusão de pessoas com deficiência no Plano de
                  Vacinação contra a Covid-19: Tércio Tinoco foi fundamental
                  para garantir que esse grupo vulnerável fosse priorizado na
                  campanha de vacinação, combatendo a exclusão e promovendo a
                  saúde para todos.
                </p>

                <p className="textos-p">
                  ° Projeto Farmacêutico Clínico: Tércio Tinoco atuou para
                  aprimorar a assistência farmacêutica nas Unidades de Pronto
                  Atendimento (UPAs), Unidades Básicas de Saúde (UBS) e Unidades
                  Mistas de Saúde, garantindo um
                </p>
                <p className="textos-p">
                  ° Combate ao descarte inadequado de radiografias: Através de
                  um projeto de sua autoria, Tércio Tinoco busca minimizar os
                  impactos ambientais e proteger a saúde pública através do
                  descarte correto de radiografias.
                </p>
              </div>
              <div>
                <img className="img-tercio" src={bio} alt="" />
              </div>
            </div>
          </div>
          <div className="flex-bio invisible">
            <div className="textos">
              <h4 className="titulos">Educação</h4>
              <p className="textos-p">
                ° Luta pela inclusão de crianças com deficiência nas escolas:
                Tércio Tinoco é um defensor incansável da educação inclusiva,
                combatendo a segregação e lutando por um ensino de qualidade
                para todas as crianças, independente de suas necessidades
                especiais.
              </p>

              <p className="textos-p">
                ° Projeto "Educação para Todos": Idealizado por Tércio, o
                projeto oferece aulas de reforço gratuitas para estudantes de
                baixa renda, reduzindo as desigualdades e promovendo a
                oportunidade de alcançar o sucesso escolar.
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio2} alt="" />
            </div>
          </div>
          <div className="flex-bio-reverse invisible">
            <div className="textos">
              <h4 className="titulos">LAZER</h4>
              <p className="textos-p">
                ° Campeonatos esportivos: Tércio Tinoco organiza e apoia campeonatos esportivos em diversas modalidades, incentivando a prática de atividade física, a integração social e a descoberta de novos talentos entre os jovens natalenses.
              </p>
              <p className="textos-p">
                ° "Sextou na Praça": Tércio Tinoco realiza esse projeto que
                oferece atividades de lazer e cultura para a população em praças
                públicas da cidade, democratizando o acesso à cultura e
                promovendo a diversão para todos. 
              </p>

              <p className="textos-p">
                ° Revitalização de praças públicas: Tércio Tinoco destina parte
                de suas emendas parlamentares para reformar e revitalizar praças
                públicas da cidade, transformando-as em espaços convidativos
                para o lazer, a prática de atividades físicas e o convívio
                social. 
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio3} alt="" />
            </div>
          </div>
          <div className="flex-bio invisible">
            <div className="textos">
              <h4 className="titulos">Transporte</h4>
              <p className="textos-p">
                ° Implementação de Linhas de Integração Social (LIS): O vereador
                defende a implementação de Linhas de Integração Social (LIS),
                que oferecem transporte gratuito para pessoas com deficiência e
                idosos, promovendo a inclusão social e a autonomia desses
                grupos.
              </p>
              <p className="textos-p">
                ° Implementação de ônibus elétricos: O vereador defende a
                implantação de ônibus elétricos no sistema de transporte
                público, como forma de reduzir a emissão de poluentes e
                contribuir para um meio ambiente mais sustentável.
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio4} alt="" />
            </div>
          </div>
          <div className="flex-bio-reverse invisible">
            <div className="textos">
              <h4 className="titulos">pessoas COM DEFICIÊNCIA</h4>
              <p className="textos-p">
                ° Mais ônibus adaptados: Tércio Tinoco cobra a aquisição de mais ônibus adaptados para pessoas com deficiência, garantindo o acesso ao transporte público para todos os cidadãos.
              </p>
              <p className="textos-p">
                ° Projeto Natal Praia Inclusiva: Idealizado por Tércio Tinoco, o projeto oferece atividades esportivas e de lazer, além de banho de mar assistido para pessoas com deficiência na praia de Ponta Negra. Uma iniciativa que promove a inclusão e garante o acesso de todos ao lazer e à qualidade de vida que a praia proporciona.
              </p>
            </div>
            <div>
              <img className="img-tercio" src={bio7} alt="" />
            </div>
          </div>
          <div className="flex-bio invisible">
            <div className="textos">
              <h4 className="titulos">
              Autismo
              </h4>
              <p className="textos-p">
              ° Vereador Tércio Tinoco, tem atuado incansavelmente desde o início de seu mandato por mais equidade e inclusão, especialmente para pessoas com deficiência. Entre elas, pessoas com Transtorno do Espectro Autista (TEA), foram diversas ações em prol da causa, como às leis:
              </p>
              <p className="textos-p">
              ° PL 384/2021 : Dispõe sobre a adoção do Sistema de Inclusão Escolar “ABA” para crianças e adolescentes com Transtorno do Espectro Autista (TEA), nas escolas da rede pública do município de Natal/RN.
              </p>
              <p className="textos-p">
              ° PL 442/2021: Fixa prazo indeterminado de validade para o laudo médico pericial que ateste deficiência irreversível, no âmbito do município de Natal.
              </p>
              <p className="textos-p">
              ° PL 427/2023: Cria o programa Servidor Amigo do Autista, que obriga a capacitação de todos os servidores do município de Natal, no atendimento às pessoas com Transtorno do Espectro Autista.
              </p>

            </div>
            <div>
              <img className="img-tercio" src={bio6} alt="" />
            </div>
          </div>

         
        </div>
      </div> <div className="solicitacao">
            <img src={solicitacao} alt="" />
          </div>
    </>
  );
}

export default NossoTrabalho;
