import React from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import './PrevArrow.css';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaArrowRight
      className={`${className} arrow fa-arrow-right`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaArrowLeft
      className={`${className} arrow fa-arrow-left`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

export { NextArrow, PrevArrow };
