const socialLinks = [
    {
        url: 'https://x.com/terciotinoco',
        label: 'X icon',
        icon: 'uil uil-twitter-alt',
    },
    {
        url: 'https://www.instagram.com/terciotinoco/',
        label: 'Instagram icon',
        icon: 'uil uil-instagram',
    },
    {
        url: 'https://wa.me/message/IMG5YFUHUYM5D1',
        label: 'Whatsapp Icon',
        icon: 'uil uil-whatsapp',
    },
    {
        url: 'https://www.facebook.com/terciotinoco/?locale=pt_BR',
        label: 'Facebook icon',
        icon: 'uil uil-facebook-f',
    },
];

export default socialLinks;

