import React from "react";
import "./Mapa2.css";
function Mapa2() {
  return (
    <div className="mapa2-container">
      <iframe
        className="mapa2"
        title="Localização"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d248.0695288830566!2d-35.21669906594563!3d-5.839769258800738!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b255001a040229%3A0xb67fb9574fb34c1d!2sESCRIT%C3%93RIO%20DO%20VEREADOR%20T%C3%89RCIO%20TINOCO!5e0!3m2!1spt-BR!2sbr!4v1718152028503!5m2!1spt-BR!2sbr" 
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  );
}





export default Mapa2;
