import React from "react";
import "./Mapa.css";
function Mapa() {
  return (
    <div className="mapa-container">
      <iframe
        className="mapa"
        title="Localização"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d699.1639445446391!2d-35.20060377146096!3d-5.790107284290698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b3001309339537%3A0x67851200b9c1af96!2sR.%20Jundia%C3%AD%2C%20546%20-%20Tirol%2C%20Natal%20-%20RN%2C%2059020-120!5e0!3m2!1spt-BR!2sbr!4v1718151906097!5m2!1spt-BR!2sbr"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
      
    </div>
  );
}



export default Mapa;
